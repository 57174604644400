import { BUTTON_STYLE } from '@latitude/core/utils/constants';

export const CARD_CODE = Object.freeze({
  LR: "LR",
  GO: "GO",
  GEM: "GEM",
  '28D': "28D"
})

export const CARD_DATA = [
    {
      code: "LR",
      id: 1,
      title: "Latitude Low Rate Mastercard",
      description: "Low rate.High rewards.",
      offerBadge: "★ Choose an offer",
      mainOffer: "$300 credit back offer OR 0% for 18 months on balance transfer",
      details: [
        "Get 3% back in Latitude Rewards when you set up recurring payments direct with participating providers",
        "13.99% p.a. purchase rate",
        "$69 annual credit card fee",
      ],
      image: require("../../../images/credit-cards/low-rate-card-bolder.webp"),
      buttonProps: {
        href: "/credit-cards/low-rate",
        trackid: "cardexplorer-apply-now-low-rate",
        button: BUTTON_STYLE.PRIMARY,
        btnText: "Choose your offer"
      },
      secBtn:{
        href:"/credit-cards/low-rate",
        btnText: "Find out more",
        trackid: "cardexplorer-learn-more-low-rate"
      }
  
    },
    {
      code: "GO",
      id: 2,
      title: "Latitude GO Mastercard",
      description: "Break up your bigger buys and enjoy more time to pay.",
      offerBadge: "",
      mainOffer: "",
      details: [
        "6–60 months Interest Free plans at Harvey Norman and other retailers",
        "27.99% p.a. purchase rate",
        "$10.95 monthly credit card fee",
      ],
      image: require("../../../images/credit-cards/go-mastercard-platinum.webp"),
      buttonProps: {
        href: "https://cards.latitudefinancial.com/go",
        trackid: "cardexplorer-apply-now-gom",
        button: BUTTON_STYLE.PRIMARY,
        btnText: "Apply now"
      },
      secBtn:{
        href:"/credit-cards/gomastercard/",
        btnText: "Find out more",
        trackid: "cardexplorer-learn-more-gom"
      }
  
    },
    {
      code: "GEM",
      id: 3,
      title: "Latitude Gem Visa",
      description: "Shop Interest Free and enjoy everyday offers.",
      offerBadge: "",
      mainOffer: "",
      details: [
        "Instant 0% interest on everyday purchases of $250 or more for 6 months",
        "27.99% p.a. purchase rate",
        "$10.95 monthly credit card fee",
      ],
      image: require("../../../images/credit-cards/latitude-gem-card-bolder.webp"),
      buttonProps: {
        href: "https://cards.latitudefinancial.com/gem",
        trackid: "cardexplorer-apply-now-gem",
        button: BUTTON_STYLE.PRIMARY,
        btnText: "Apply now"
      },
      secBtn:{
        href:"/credit-cards/gem-visa",
        btnText: "Find out more",
        trackid: "cardexplorer-learn-more-gem"
      }
    },
    {
      code: "28D",
      id: 4,
      title: "Latitude 28° Platinum Mastercard",
      description: "Travel better and shop confidently.",
      offerBadge: "★ Choose an offer",
      mainOffer: "$300 bonus Latitude rewards OR $0 monthly card fee for 12 months",
      details: [
        "$0 international transaction fee on purchases overseas or online",
        "27.99% p.a. purchase rate",
        "$8 monthly credit card fee",
      ],
      image: require("../../../images/credit-cards/28-global-platinum-card-bolder.webp"),
      buttonProps: {
        href: "/credit-cards/28-degrees",
        trackid: "cardexplorer-apply-now-twentyEightD",
        button: BUTTON_STYLE.PRIMARY,
        btnText: "Find out more"
      },
      secBtn:{
        href:"",
        btnText: "",
        trackid: ""
      }
    }
];